import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import isEmpty from "is-empty"

import { calculateMarkup, cryptoPurchaseApi } from "../../api/currency"
import { toast } from "../../lib/toast"
import { Spinner } from "react-bootstrap"
import { toFixedDown } from "../../lib/roundOf"
import { percentConvertPrice } from "../../lib/calculation"

const ConfirmModal = ({
  priceCnvData,
  show,
  handleClose,
  handleShow1,
  handleClose1,
  currentData,
  coin,
}) => {
  const initialValues = {
    amount: 0,
    quantity: 0,
    currentMarketPrice: 0,
    address: "",
    currency: "",
    markupPercentage:"",
    withdrawFee:""
  }

  const {
    values,
    errors,
    touched,
    setValues,
    setErrors,
    handleChange: formikHandleChange,
    handleSubmit,
  } = useFormik({ initialValues, onSubmit: cryptoPurchase })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  // const priceCnvData = useSelector(state => state.priceConversion)

  // const decimals =
  //   coin == "BTC"
  //     ? 8
  //     : coin == "ETH"
  //     ? 8
  //     : process.env.REACT_APP_MODE !== "production"
  //     ? 2
  //     : 18

  // const convertPriceWithMarkupPercentage = toFixedDown(calculateMarkup(priceCnvData.convertPrice_cad,currentData.markupPercentage))

  // console.log(convertPriceWithMarkupPercentage,"convertPriceWithMarkupPercentage",priceCnvData?.convertPrice_cad,currentData?.markupPercentage);

  // const quantity = (values.amount / convertPriceWithMarkupPercentage).toFixed(decimals)

  // const [fee,setFee] = useState((values.amount / convertPriceWithMarkupPercentage).toFixed( 
  //      coin == "BTC"
  //       ? 8
  //       : coin == "ETH"
  //       ? 8
  //       : process.env.REACT_APP_MODE !== "production"
  //       ? 2
  //       : 18))

  const calculateMarkupPercentage = () =>{
    let convertPrice = priceCnvData && priceCnvData?.convertPrice_cad || 0
    let markupPercentage = currentData && currentData?.markupPercentage || 0
    let percentagePrice = calculateMarkup(convertPrice,markupPercentage)
    return toFixedDown(percentagePrice)
  }

  // user input,currnet marketPrice with markup - fee

  const calculateQty = () => {
    const decimals =  coin == "BTC"
    ? 8
    : coin == "ETH"
    ? 8
    : process.env.REACT_APP_MODE !== "production"
    ? 2
    : 18
    const currentPriceWithMarkup = calculateMarkupPercentage()
    const amount = currentData.amount
    const fee = percentConvertPrice(values.amount,values.withdrawFee)
    return toFixedDown((amount - fee)/currentPriceWithMarkup,decimals)
  }

  // useEffect(() => {
  //   if (values.withdrawFee) {
  //     const newFee = (
  //       quantity -
  //       (
  //         percentConvertPrice(values.amount, values.withdrawFee) /
  //         convertPriceWithMarkupPercentage
  //       ).toFixed(decimals)
  //     );
  //     setFee(newFee);
  //   }
  // }, [values.withdrawFee, quantity, convertPriceWithMarkupPercentage]);
  
  // useEffect(() => {
  //   setValues(prev => ({
  //     ...prev,
  //     quantity: toFixedDown(fee),
  //   }));
  // }, [fee]);

  const handleManualChange = event => {
    if (
      (event.target.value.includes(".") &&
        event.target.value.split(".")[1].length <= 2) ||
      !event.target.value.includes(".")
    ) {
      formikHandleChange(event)
    } else {
      setErrors({ ...errors, amount: "Decimal must be less than or equal to 2" })
    }
  }
  async function cryptoPurchase(values, { setErrors, resetForm }) {
    try {
      console.log("valuesvaluesvalues", values)
      const data = {
        ...values,
      }
      console.log(data, "datass")
      setLoading(true)
      const { success, message, errors } = await cryptoPurchaseApi(data)
      if (success) {
        setLoading(false)
        toast({ message, type: "success" }, dispatch)
        resetForm()
        handleClose()
        return
      } else if (!success && !isEmpty(errors)) {
        setLoading(false)
        setErrors(errors)
        return
      } else {
        setLoading(false)
        toast({ message, type: "error" }, dispatch)
      }
    } catch (error) {
      setLoading(false)
      console.log("handleSubmit_error", error)
      toast({ message: "Something went wrong", type: "error" }, dispatch)
    }
  }

  useEffect(() => {
    if (currentData) {
      console.log(currentData.address, "datass")
      setValues(prev => ({
        ...prev,
        amount: currentData.amount,
        quantity: calculateQty(),
        currentMarketPrice:calculateMarkupPercentage(),
        address: currentData.address,
        currency: currentData.currency,
        markupPercentage:currentData.markupPercentage,
        withdrawFee:currentData.withdrawFee
      }))
    }
  }, [currentData,priceCnvData])

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="lg"
      centered
      className="order_confrim_model"
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Your Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Please review the information below and confirm your order to execute it. After
          you confirm, the {currentData?.currency} is immedeately sent to the deposit
          address you provided.
        </p>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Amount Spend</Form.Label>
          <Form.Control
            type="number"
            id="amount"
            name="amount"
            placeholder="Amount"
            className="primary_input"
            value={values.amount}
            onChange={handleManualChange}
            disabled
          />
          <p className="red_text text-danger">
            {(touched.amount && errors.amount) || ""}
          </p>
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>You will receive</Form.Label>
          <Form.Control
            type="number"
            id="quantity"
            name="quantity"
            placeholder="Amount"
            className="primary_input"
            value={values.quantity}
            disabled
          />
          <p className="red_text">{(touched.quantity && errors.quantity) || ""}</p>
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{coin} Rate </Form.Label>
          <Form.Control
            type="text"
            id="currentMarketPrice"
            name="currentMarketPrice"
            className="primary_input"
            // value={convertPriceWithMarkupPercentage}
            value={values.currentMarketPrice}
            disabled
          />
          <p className="red_text">
            {(touched.currentMarketPrice && errors.currentMarketPrice) || ""}
          </p>
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Deposit Address</Form.Label>
          <Form.Control
            type="text"
            id="address"
            name="address"
            placeholder="Wallet Address"
            onChange={formikHandleChange}
            className="primary_input"
            value={values.address}
            disabled
          />
          <p className="red_text text-danger">
            {(touched.address && errors.address) || ""}
          </p>
        </Form.Group>
        <div className="model_btn">
          <Button
            className=""
            // onClick={handleShow1}
            onClick={handleSubmit}
            onHide={handleClose1}
          >
            {loading ? <Spinner size="sm" /> : "Confirm"}
            <img
              src={require("../../assets/image/arrow_orange.png")}
              className="img-fluid orange_arrow"
            />
            <img
              src={require("../../assets/image/btn-arrow.png")}
              className="img-fluid white_arrow"
            />
          </Button>
        </div>
        <p>
          *Initial transaction may be held until confirmed as legitimate, all subsequent
          are instant.
        </p>
      </Modal.Body>
    </Modal>
  )
}

export default ConfirmModal
