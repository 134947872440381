import { createSlice } from "@reduxjs/toolkit"
import { getPriceData } from "../../api/currency"

const initialState = []

export const fetchPriceCnvData = async (dispatch) => {
  try { 
    const { success, result } = await getPriceData()
    if (success) {
      dispatch(setPriceConversion(result.data))
    }
  } catch (error) {
    console.log("error", error)
  }
}

const priceConversionSlice = createSlice({
  name: "priceConversion",
  initialState,
  reducers: {
    setPriceConversion: (state, action) => {
      state = action.payload
      return state
    }
  },
})

export const { setPriceConversion } = priceConversionSlice.actions
export default priceConversionSlice.reducer
