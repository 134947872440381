import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { otpVerification, updatePassword } from "../../api/user"
import { Button, Form, InputGroup, Modal } from "react-bootstrap"
import { toast } from "../../lib/toast"
import isEmpty from "is-empty"
import { emailFormat } from "../../lib/stringCase"

import { encryptObject } from "../../lib/crypto"
import { removeAuthToken } from "../../utils/cookie"
import { resetAccount } from "../../redux/slices/account"
import { useNavigate } from "react-router-dom"
import { removeAuthorization } from "../../config/axios"

let initialFormValue = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
  otp: "",
}
export default function ChangePassword({ show, onHide }) {
  const dispatch = useDispatch()
  //   const history = useRouter()
  const navigate = useNavigate()

  const { account } = useSelector(state => state)
  const [formValue, setFormValue] = useState(initialFormValue)
  const [error, setError] = useState({})
  const [loader, setLoader] = useState(false)
  const [otpButtonType, setOTPButtonType] = useState("Send")
  const [check, setCheck] = useState(false)
  const [isPassword, setIsPassword] = useState(false)
  const [isPassword1, setIsPassword1] = useState(false)
  const [isPassword2, setIsPassword2] = useState(false)

  const [passwordVisibility, setPasswordVisibility] = useState({
    oldPassword: false,
    password: false,
    confirmPassword: false,
  })

  let { oldPassword, password, confirmPassword, otp } = formValue

  const handleChange = e => {
    let { name, value } = e.target
    setFormValue({ ...formValue, ...{ [name]: value } })
    setError(prev => ({
      ...prev,
      [name]: "",
    }))
    setLoader(false)
  }

  const handleSendCode = async () => {
    try {
      let data = {
        roleType: 1,
        requestType: "ChangePass",
      }
      const { success, message } = await otpVerification(data)
      if (success) {
        // console.log(new Date(new Date().getTime() + 3 * 60 * 1000),new Date());
        setTime(new Date(new Date().getTime() + 3 * 60 * 1000))
        setOTPButtonType("Resend")
        toast({ message, type: "success" }, dispatch)
      } else {
        toast({ message, type: "success" }, dispatch)
      }
    } catch (err) {
      toast({ message: err.response.data.message, type: "error" }, dispatch)
    }
  }

  const onOTPsend = async e => {
    e.preventDefault()
    try {
      let req = {
        requestType: "ChangePass",
      }
      const result = await otpVerification(req)
      if (result.data.success) {
        setOTPButtonType("Resend")
        toast({ message: result.data.message, type: "success" }, dispatch)
      }
    } catch (err) {
      toast({ message: err.response.data.message, type: "error" }, dispatch)
    }
  }

  const handleLogout = () => {
    removeAuthorization()
    removeAuthToken()
    dispatch(resetAccount())
    navigate("/login")
  }

  const [time,setTime] = useState()

  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    let interval;
    if (time) {
      interval = setInterval(() => {
        const remainingTime = Math.max(0, Math.floor((time - new Date()) / 1000));
        setTimeLeft(remainingTime);
        if (remainingTime === 0) {
          clearInterval(interval);
        }
        console.log("returnnotworks");
      }, 1000);
    }
    console.log("returnworks");
    return () => clearInterval(interval);
  }, [time]);

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      let reqData = {
        oldPassword,
        password,
        confirmPassword,
        otp,
      }
      if (
        !check &&
        !isEmpty(reqData.oldPassword) &&
        !isEmpty(reqData.password) &&
        !isEmpty(reqData.confirmPassword)
      ) {
        return toast({ message: "Please enable check box", type: "error" }, dispatch)
      }
      console.log("reqData----------", reqData)

      reqData = encryptObject(reqData)

      const { success, message, errors } = await updatePassword({ id: reqData })

      if (success) {
        toast({ message, type: "success" }, dispatch)
        setTimeout(() => {
          handleLogout()
        }, 2000)
      } else if (!success && !isEmpty(errors)) {
        setError(errors)
      } else {
        toast({ message, type: "error" }, dispatch)
      }
    } catch (err) {
      console.log("errorrrr:", err)
      if (!isEmpty(err?.response?.data?.errors)) {
        setError(err.response.data.errors)
      }
      if (!isEmpty(err?.response?.data?.message))
        toast({ message: err.response.data.message, type: "error" }, dispatch)
    }
  }

  const handleClose = () => {
    // setpassword_modal(false)
    onHide()
    setError({})
    setFormValue(initialFormValue)
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className="order_confrim_model input_group_model"
      >
        <Modal.Header closeButton>
          <Modal.Title>Change password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>Old password *</Form.Label>
            <InputGroup className={`mb-3 `}>
              <Form.Control
                type={!passwordVisibility.oldPassword ? "password" : "text"}
                placeholder="Please enter the old password"
                name="oldPassword"
                onChange={handleChange}
              />
              <InputGroup.Text id="basic-addon2">
                {" "}
                <div
                  className="showpass_icon"
                  onClick={() =>
                    setPasswordVisibility(prev => ({
                      ...prev,
                      oldPassword: !prev.oldPassword,
                    }))
                  }
                >
                  <i
                    className={` ${
                      passwordVisibility.oldPassword
                        ? "fa-solid fa-eye"
                        : "fa-solid fa-eye-slash"
                    }`}
                  ></i>{" "}
                </div>
              </InputGroup.Text>
            </InputGroup>
            <p className="text-danger">{error?.oldPassword}</p>

            <Form.Label>New password *</Form.Label>
            <div className="position_eye">
              <InputGroup className={`mb-3`}>
                <Form.Control
                  type={!passwordVisibility.password ? "password" : "text"}
                  placeholder="Please enter a new password"
                  name="password"
                  onChange={handleChange}
                />
                <InputGroup.Text id="basic-addon2">
                  {" "}
                  <div
                    className="showpass_icon"
                    onClick={() =>
                      setPasswordVisibility(prev => ({
                        ...prev,
                        password: !prev.password,
                      }))
                    }
                  >
                    <i
                      className={` ${
                        passwordVisibility.password
                          ? "fa-solid fa-eye"
                          : "fa-solid fa-eye-slash"
                      }`}
                    ></i>{" "}
                  </div>
                </InputGroup.Text>
              </InputGroup>
              <p className="text-danger">{error?.password}</p>
            </div>

            <Form.Label>Confirm New Password *</Form.Label>
            <InputGroup>
              <Form.Control
                type={!passwordVisibility.confirmPassword ? "password" : "text"}
                placeholder="Please enter a new password again"
                name="confirmPassword"
                onChange={handleChange}
              />
              <InputGroup.Text id="basic-addon2">
                {" "}
                <div
                  className="showpass_icon"
                  onClick={() =>
                    setPasswordVisibility(prev => ({
                      ...prev,
                      confirmPassword: !prev.confirmPassword,
                    }))
                  }
                >
                  <i
                    className={` ${
                      passwordVisibility.confirmPassword
                        ? "fa-solid fa-eye"
                        : "fa-solid fa-eye-slash"
                    }`}
                  ></i>{" "}
                </div>
              </InputGroup.Text>
            </InputGroup>
            <p className="text-danger">{error?.confirmPassword}</p>

            <Form.Label className="d-flex">
              <span>Your secure email address is {emailFormat(account?.email)}</span>
              <span className="ms-1">*</span>
            </Form.Label>
            <InputGroup className={`mb-3`}>
              <Form.Control
                placeholder="Please enter your email verification code "
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                name="otp"
                onChange={handleChange}
              />
              <Button id="basic-addon2" className={`cursor_pointer`} disabled={((error.oldPassword !== "" && error.password !== "" && error.confirmPassword !== "") || (formValue.oldPassword === "" || formValue.password === "" || formValue.confirmPassword === "")) || (timeLeft > 0)}>
                {" "}
                <span onClick={handleSendCode}>{otpButtonType}</span>{" "}
              </Button>
            </InputGroup>
            <p className="text-danger">{error?.otp}</p>

            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Check
                onClick={() => setCheck(check ? false : true)}
                type="checkbox"
                id={`default-checkbox`}
                label={`I have been informed that if I log in to this account on a new device after changing the login password, I will be temporarily unable to withdraw coins within 24 hours`}
              />
            </Form.Group>
          </Form>
          <div className="model_btn mt-4">
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={e => handleSubmit(e)} disabled={loader} type="submit">
              {loader ? <i className="fa fa-spinner fa-spin"></i> : "Confirm"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
