import React, { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateProfilePicture } from "../api/user"
import EmailChange from "../component/account/EmailChange"
import Footer from "../component/footer"
import NavbarInner from "../component/navbar-inner"
import Sidebar from "../component/sidebar"
import config from "../config"
import { toast } from "../lib/toast"
import { Row, Col, Button } from "react-bootstrap"
import { setAccount } from "../redux/slices/account"
const now = new Date()

export default function Account() {
  const dispatch = useDispatch()
  const { account } = useSelector(state => state)

  const [show, setShow] = useState(false)

  const fileInputRef = useRef(null)

  const handleClose = () => setShow(false)

  const handleImageClick = () => {
    fileInputRef.current.click()
  }
  const updateProfilePic = async file => {
    const formData = new FormData()
    formData.append("profilePic", file)
    console.log(file,"profilePic");
    try {
      const { success, message} = await updateProfilePicture(formData, dispatch)
      if (success) {
        toast({ message, type: "success" }, dispatch)
      } else {
        toast({ message, type: "error" }, dispatch)
      }
    } catch (error) {
      toast({ message: "Something went wrong", type: "error" }, dispatch)
      console.log(error)
    }
  }

  const handleChange = event => {
    if (event.target.name == "profilePicture") {
      const file = event.target.files[0]
      if (file) {
        updateProfilePic(file)
      }
    }
  }
  const obfuscateEmail = email => {
    // console.log(email,"email");

    const [localPart, domain] = email.split("@")

    // console.log(localPart,domain);

    if (!domain || !localPart) {
      return email
    }

    const localLength = localPart.length

    const numMaskChars = localLength - 2

    const maskedLocalPart =
      localPart.charAt(0) + "*".repeat(numMaskChars) + localPart.charAt(localLength - 1)

    // console.log(`${maskedLocalPart}@${domain} check`);

    return `${maskedLocalPart}@${domain}`
  }
  return (
    <>
      <NavbarInner />
      <div className="dashpage_wrapper">
        <Sidebar />
        <main className="main_inner_content">
          <div className="dash_section">
            <h2 className="dash_title">Account Settings</h2>
            <div className="com-border orange">
              <Row className="justify-content-center align-items-center">
                <Col xxl="3" xl="3" lg="5" md="5" sm="5">
                  <div className="account_profile">
                    <div
                      className="account_head account_before"
                      onClick={handleImageClick}
                    >
                      <div>
                        <img
                          src={`${config.API_HOST}/${account?.profilePic || ""}`}
                          alt="Icon"
                          width={96}
                          height={96}
                          className="rounded-circle"
                          style={{
                            // borderRadius: "50%",
                            backgroundColor: "#fff",
                          }}
                        />

                        {/* <img
                        src={require("../assets/image/Ellipse 1.png")}
                        alt="Icon"
                        width={96}
                        height={96}
                        style={{ borderRadius: "50%" }}
                      /> */}
                        <input
                          type="file"
                          ref={fileInputRef}
                          accept=".jpg,.png,.jpeg"
                          style={{ display: "none" }}
                          onChange={handleChange}
                          name="profilePicture"
                        />
                      </div>
                      <div>
                        <h4 className="mb-3">{account?.userName}</h4>
                        <Button>Upload Profile Photo</Button>
                        <p className="mt-1" style={{ fontSize: "0.7rem" }}>
                          (Note: .jpg/.png/.jpeg Max 500KB)
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xxl="9" xl="10" lg="8" md="9" sm="9">
                  <div className="account_profile2">
                    <div className="account_detail account_detail1 account_before">
                      <p>
                        <img
                          src={require("../assets/image/account_icon1.png")}
                          alt="Icon"
                        />{" "}
                        Email
                      </p>
                      <h6>{account?.email || ""}</h6>
                    </div>
                    <div className="account_detail account_detail1 account_before">
                      <p>
                        <img
                          src={require("../assets/image/account_icon2.png")}
                          alt="Icon"
                        />{" "}
                        IP Address
                      </p>
                      <h6>49.37.192.237</h6>
                    </div>
                    <div className="account_detail">
                      <p>
                        <img
                          src={require("../assets/image/account_icon4.png")}
                          alt="Icon"
                        />{" "}
                        Last Login
                      </p>
                      <h6>
                        {now.getFullYear()}-{String(now.getMonth() + 1).padStart(2, "0")}-
                        {String(now.getDate()).padStart(2, "0")}{" "}
                        {String(now.getHours()).padStart(2, "0")}:
                        {String(now.getMinutes()).padStart(2, "0")}:
                        {String(now.getSeconds()).padStart(2, "0")}
                      </h6>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="com-border email_detail mt-4">
              <div className="email_head">
                <img src={require("../assets/image/mail.png")} alt="Icon" />
                <div>
                  <h6>Email</h6>
                  <p>Used to verify withdrawals,API creation, etc.</p>
                </div>
              </div>
              <div className="email_head">
                <p>
                  <img
                    src={require("../assets/image/verified.png")}
                    alt="Icon"
                    className="verify_img"
                  />
                  <span>{obfuscateEmail(account?.email || "")}</span>
                </p>

                <button onClick={() => setShow(true)} className="border_btn border_btn1">
                  <span>Change</span>
                  <img
                    src={require("../assets/image/btn-arrow.png")}
                    className="img-fluid ms-2"
                  />
                </button>
              </div>
            </div>
            <EmailChange show={show} handleClose={handleClose} />
          </div>
          <Footer />
        </main>
      </div>
    </>
  )
}
