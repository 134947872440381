import { combineReducers } from 'redux';

import authReducer from './slices/auth';
import accountReducer from './slices/account';
import toastReaducer from './slices/toast';
import walletReducer from './slices/wallet';
import currencyReducer from './slices/currency';
import notificationReducer  from './slices/notification';
import priceConversionReducer from './slices/priceConversion';

const rootReducer = (asyncReducer) => (state, action) => {
  const combinedReducers = combineReducers({
    auth: authReducer,
    account: accountReducer,
    toast: toastReaducer,
    wallet: walletReducer,
    currency: currencyReducer,
    notification:notificationReducer,
    priceConversion:priceConversionReducer,
    ...asyncReducer
  });

  return combinedReducers(state, action);
};

export default rootReducer;
