import React, { useEffect, useState } from "react"
import { Col, Row, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { emailMe, sampleSocket } from "../api/user"
import { retrieveUserBalance } from "../api/wallet"
import CryptoCoins from "../component/cryptocoins/cryptocoins"
import Footer from "../component/footer"
import InstructionModal from "../component/funding/deposit/instructionModal"
import Transactions from "../component/funding/history/Transactions"
import ManualTrigger from "../component/funding/manual/ManualTriggerModal"
import WithdrawModal from "../component/funding/withdraw/WithdrawModal"
import NavbarInner from "../component/navbar-inner"
import Sidebar from "../component/sidebar"
import { toast } from "../lib/toast"
import { getFeeData } from "../api/notification"

export default function Funding() {
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [instModal, setInstModal] = useState(false)
  const [manualModal, setManualModal] = useState(false)

  const [balance, setBalance] = useState(0)
  const [feeData, setFeeData] = useState({
    bankWire: {
      depositFee: 0,
      minimumDeposit: 0,
      maximumDeposit: 0,
    },
    interac: {
      depositFee: 0,
      minimumDeposit: 0,
      maximumDeposit: 0,
    },
  })

  const handleManualClose = () => setManualModal(false)

  // const [isDisabledTrigger,setIsDisabledTrigger] = useState(false)

  const handleManualOpen = () => {
    if (kycStatus.idProof !== "approved") {
      // setIsDisabledTrigger(true)
      toast({ message: "KYC is not verified", type: "error" }, dispatch)
    } else {
      setManualModal(true)
    }
  }

  const handleClose = () => setShow(false)

  // const [isDisabledWithdraw,setIsDisabledWithdraw] = useState(false)

  const handleOpen = () => {
    if (kycStatus.idProof !== "approved") {
      // setIsDisabledWithdraw(true)
      toast({ message: "KYC is not verified", type: "error" }, dispatch)
    } else {
      setShow(true)
    }
  }

  const handleInstModalClose = () => setInstModal(false)
  const handleInstModalOpen = () => setInstModal(true)

  const fetchBalance = async () => {
    const { success, result } = await retrieveUserBalance({ type: "balance" })
    if (success) setBalance(result.data.balance)
  }
  const emailCall = async () => {
    const { success, result, message } = await emailMe()
    if (success) {
      toast({ message, type: "success" }, dispatch)
    } else {
      toast({ message: "Something went wrong", type: "error" }, dispatch)
    }
  }

  const fetchFeeData = async () => {
    try {
      const { success, result } = await getFeeData()
      if (success) {
        console.log(result,"result");
        
        setFeeData(result)
      }
    } catch (error) {}
  }

  const [kycStatus, setKycStatus] = useState({ idProof: "", addressProof: "" })

  // console.log(kycStatus, "data");

  const kycData = useSelector(state => state.account.kycStatus)

  const isCad = useSelector(state => state.currency[0])

  useEffect(() => {
    fetchBalance()
    fetchFeeData()
    if (kycData) {
      setKycStatus(kycData)
    }
  }, [kycData])

  return (
    <>
      <NavbarInner />
      <div class="dashpage_wrapper">
        <Sidebar />
        <main class="main_inner_content">
          <div className="dash_section">
            <CryptoCoins />
            <h2 className="dash_title">Fund Your Account</h2>
            <Row className="height_eq_div">
              <Col lg={9}>
                <div className="table_dash_card nav_history">
                  <div className="table_dash_card1">
                    <Table responsive className="primary_table table-hover  ">
                      <thead>
                        <tr>
                          <th>Method</th>
                          <th>Limits</th>
                          <th>Fee</th>
                          <th>Next Steps</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Interac e-Transfer</td>
                          <td>
                            $ {feeData.interac.minimumDeposit} - ${" "}
                            {feeData.interac.maximumDeposit}
                          </td>
                          <td>{feeData.interac.depositFee} %</td>
                          <td>
                            {isCad && isCad.status === "active" && (
                              <button
                                className="border_btn"
                                onClick={handleInstModalOpen}
                              >
                                <span>Deposit</span>
                                <img
                                  src={require("../assets/image/arrow_orange.png")}
                                  className="img-fluid ms-2"
                                />
                              </button>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Bank Wire</td>
                          <td>
                            $ {feeData.bankWire.minimumDeposit} - ${" "}
                            {feeData.bankWire.maximumDeposit}
                          </td>
                          <td>{feeData.bankWire.depositFee} %</td>
                          <td>
                            <button className="border_btn" onClick={emailCall}>
                              <span>Email Me</span>
                              <img
                                src={require("../assets/image/arrow_orange.png")}
                                className="img-fluid ms-2"
                              />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="withdraw_card">
                  <div>
                    <h3>Deposit Amount</h3>
                    <h4>
                      {balance.toFixed(2)} <span>CAD</span>
                    </h4>
                  </div>
                  {isCad && isCad.status === "active" && (
                    <button className="border_btn" onClick={handleOpen}>
                      <span>Withdraw</span>
                      <img
                        src={require("../assets/image/btn-arrow.png")}
                        className="img-fluid ms-2"
                      />
                    </button>
                  )}
                  {isCad && isCad.status === "active" && (
                    <button className="border_btn" onClick={handleManualOpen}>
                      <span>Trigger</span>
                      <img
                        src={require("../assets/image/btn-arrow.png")}
                        className="img-fluid ms-2"
                      />
                    </button>
                  )}
                </div>
              </Col>
            </Row>
            <Transactions />
          </div>
          <WithdrawModal
            show={show}
            handleClose={handleClose}
            balance={balance}
            fetchBalance={fetchBalance}
          />
          <InstructionModal show={instModal} handleClose={handleInstModalClose} />
          <ManualTrigger
            show={manualModal}
            handleClose={handleManualClose}
            fetchBalance={fetchBalance}
          />
          <Footer />
        </main>
      </div>
    </>
  )
}
