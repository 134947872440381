import { capitalize } from "@mui/material"
import { useFormik } from "formik"
import isEmpty from "is-empty"
import moment from "moment/moment"
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { useDispatch, useSelector } from "react-redux"
import * as yup from "yup"
import {
  closeTicketApi,
  getSupportCategory,
  getSupportTickets,
  postSupportTicket,
  postSupportTicketReply,
} from "../api/user"
import Footer from "../component/footer"
import NavbarInner from "../component/navbar-inner"
import Sidebar from "../component/sidebar"
import { toast } from "../lib/toast"
import config from "../config"
import { Link } from "react-router-dom"

const initialValuesCreate = {
  title: "",
  description: "",
  category: "",
  attachment: "",
}

const validationSchemaCreate = yup.object({
  category: yup
    .string()
    .length(24, "Invalid ticket type")
    .required("Ticket type is required"),
  title: yup.string().required("Subject is required"),
  message: yup
    .string()
    .min(10, "Message should have minimum 10 characters")
    .max(1000, "Message can have maximum of 1000 characters")
    .notRequired(),
})

const formatNumber = index => {
  if (index + 1 < 10) {
    return `0${index + 1}`
  }
  return index + 1
}

export default function Support(props) {
  const [visibleDiv, setVisibleDiv] = useState("CreateTicket")

  const { account } = useSelector(state => state)

  const showCreateDiv = () => {
    setVisibleDiv("CreateTicket")
  }
  const showDetailsDiv = () => {
    setVisibleDiv("TicketDetails")
  }
  const fileInputRef = useRef(null)

  const replyFileInputRef = useRef(null)

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
    if (replyFileInputRef.current) {
      console.log("fille")
      replyFileInputRef.current.click()
    }
  }

  const dispatch = useDispatch()
  const [category, setCategory] = useState([])
  const [tickets, setTickets] = useState([])
  const [activeTicket, setActiveTicket] = useState(0)
  const [loading, setLoading] = useState({ create: false, reply: false })

  const { values, handleChange, handleSubmit, setErrors, setValues, errors } = useFormik({
    initialValues: initialValuesCreate,
    validationSchema: validationSchemaCreate,
    onSubmit: submitSupportTicket,
  })

  const handleFileChange = e => {
    let file = e.target.files[0]
    console.log(file)
    if (!file.type.startsWith("image") && !file.type.startsWith("video")) {
      return setErrors({ attachment: "Invalid file format" })
    } else if (file.size > 5 * 1024 * 1024) {
      return setErrors({ attachment: "Maximum file size is 5MB" })
    }
    setValues(prev => ({ ...prev, attachment: e.target.files[0] }))
  }

  // Support Category
  const fetchSupportCategory = useCallback(async () => {
    try {
      const { success, result, message } = await getSupportCategory()
      console.log("result==", result)

      if (success) {
        setCategory(result)
      } else {
        toast({ message: message, type: "error" }, dispatch)
      }
    } catch (error) {
      toast({ message: "Something went wrong", type: "error" }, dispatch)
      console.log(error)
    }
  }, [dispatch])

  useEffect(() => {
    fetchSupportCategory()
  }, [fetchSupportCategory])

  // Support tickets
  const fetchSupportTickets = useCallback(async () => {
    try {
      const { success, result, message } = await getSupportTickets()
      if (success) {
        setTickets(result)
      } else {
        toast({ message: message, type: "error" }, dispatch)
      }
    } catch (error) {
      toast({ message: "Something went wrong", type: "error" }, dispatch)
      console.log(error)
    }
  }, [dispatch])

  useEffect(() => {
    fetchSupportTickets()
  }, [fetchSupportTickets])

  // Submit Ticket
  async function submitSupportTicket(values, { resetForm }) {
    try {
      setLoading(prev => ({ ...prev, create: true }))

      const formData = new FormData()

      formData.append("category", values.category)
      formData.append("title", values.title)
      formData.append("description", values.description)
      formData.append("attachment", values.attachment)

      const { success, message, errors } = await postSupportTicket(formData)
      if (success) {
        toast({ message: message, type: "success" }, dispatch)
        resetForm()
        await fetchSupportTickets()
        setLoading(prev => ({ ...prev, create: false }))
      } else {
        if (!isEmpty(errors)) setErrors(errors)
        if (!isEmpty(message)) toast({ message: message, type: "error" }, dispatch)
        setLoading(prev => ({ ...prev, create: false }))
      }
    } catch (error) {
      toast({ message: "Something went wrong", type: "error" }, dispatch)
      console.log(error)
      setLoading(prev => ({ ...prev, create: false }))
    }
  }
  async function closeTicket() {
    try {
      setLoading(prev => ({ ...prev, create: true }))

      const { success, message, errors } = await closeTicketApi({
        ticketId: tickets[activeTicket]._id,
      })
      if (success) {
        toast({ message: message, type: "success" }, dispatch)
        await fetchSupportTickets()
        setLoading(prev => ({ ...prev, create: false }))
      } else {
        if (!isEmpty(errors)) setErrors(errors)
        if (!isEmpty(message)) toast({ message: message, type: "error" }, dispatch)
        setLoading(prev => ({ ...prev, create: false }))
      }
    } catch (error) {
      toast({ message: "Something went wrong", type: "error" }, dispatch)
      console.log(error)
      setLoading(prev => ({ ...prev, create: false }))
    }
  }

  // Reply Ticket
  const {
    values: replyValues,
    handleChange: replyHandleChange,
    setValues: replySetValues,
    handleSubmit: replyHandleSubmit,
    setErrors: setReplyErrors,
    resetForm: resetReplyForm,
  } = useFormik({
    initialValues: { message: "", attachment: "" },
    onSubmit: submitReply,
  })

  const handleReplyFileChange = e => {
    let file = e.target.files[0]
    if (!file.type.startsWith("image") && !file.type.startsWith("video")) {
      return setReplyErrors({ attachment: "Invalid file format" })
    } else if (file.size > 5 * 1024 * 1024) {
      return setReplyErrors({ attachment: "Maximum file size is 5MB" })
    }
    replySetValues(prev => ({ ...prev, attachment: e.target.files[0] }))
  }

  useEffect(() => {
    resetReplyForm()
  }, [activeTicket, resetReplyForm])

  async function submitReply(values, { resetForm }) {
    try {
      const formData = new FormData()

      formData.append("message", values.message)
      formData.append("attachment", values.attachment)
      formData.append("ticketId", tickets[activeTicket]._id)

      const { success, message, errors, result } = await postSupportTicketReply(formData)

      if (success) {
        toast({ message: message, type: "success" }, dispatch)
        resetForm()
        setTickets(prev => {
          const updatedTickets = [...prev]
          updatedTickets[activeTicket].reply.push(result)
          return updatedTickets
        })
      } else {
        if (!isEmpty(errors)) setErrors(errors)
        if (!isEmpty(message)) toast({ message: message, type: "error" }, dispatch)
      }
    } catch (error) {
      toast({ message: "Something went wrong", type: "error" }, dispatch)
    }
  }

  return (
    <>
      <NavbarInner />
      <div class="dashpage_wrapper">
        <Sidebar />
        <main class="main_inner_content">
          <div className="dash_section">
            <h2 className="dash_title">Support Ticket</h2>
            <Row className="mb-5">
              <Col xl={5} lg={6} className="mb-4">
                <div className="com-border suppot-height  mt-4">
                  <div className="support_tic_btngrp">
                    <Button
                      onClick={showCreateDiv}
                      className={visibleDiv === "CreateTicket" ? "active" : ""}
                    >
                      Create Tickets
                    </Button>
                    <Button
                      onClick={showDetailsDiv}
                      className={visibleDiv === "TicketDetails" ? "active" : ""}
                    >
                      Ticket Details
                    </Button>
                  </div>

                  {visibleDiv === "CreateTicket" && (
                    <>
                      <Form className="primary_form">
                        <Row>
                          <Col lg={12} className="mb-3">
                            <Form.Group>
                              <Form.Label htmlFor="Nickname">Subject</Form.Label>
                              <Form.Control
                                className="input_style"
                                placeholder="Enter a brief subject of your issue"
                                type="text"
                                onChange={handleChange}
                                name="title"
                                value={values.title}
                              ></Form.Control>
                              <p className="text-danger">{errors?.title || ""}</p>
                            </Form.Group>
                          </Col>
                          <Col lg={12} className="mb-3">
                            <Form.Group>
                              <Form.Label htmlFor="Nickname">Category</Form.Label>
                              <Form.Select
                                className="input_style"
                                onChange={handleChange}
                                name="category"
                                value={values.category}
                              >
                                <option>Select Category</option>
                                {!isEmpty(category) &&
                                  category.map(item => {
                                    return (
                                      <option value={item._id}>
                                        {item.categoryName}
                                      </option>
                                    )
                                  })}
                                {/* <option>Account Problem</option>
                                <option>Login Issue</option>
                                <option>Deposit Issue</option>
                                <option>Withdraw Issue</option> */}
                              </Form.Select>
                              <p className="text-danger">{errors?.category || ""}</p>
                            </Form.Group>
                          </Col>
                          <Col lg={12} className="mb-3">
                            <Form.Group>
                              <Form.Label htmlFor="Nickname">Description</Form.Label>
                              <Form.Control
                                className="input_style"
                                type="text"
                                placeholder="Describe your issue in detail"
                                as="textarea"
                                rows={5}
                                name="description"
                                onChange={handleChange}
                                value={values.description}
                              ></Form.Control>
                              <p className="text-danger">{errors?.description || ""}</p>
                            </Form.Group>
                          </Col>
                          <Col lg={12} className="mb-3 attach">
                            <Form.Label htmlFor="Nickname">Attach Files</Form.Label>
                            <div className="support_upload_btn support_upload_border">
                              <Form.Control
                                ref={fileInputRef}
                                type="file"
                                hidden
                                accept=".jpg,.jpeg,.png,.mp4"
                                onChange={handleFileChange}
                              ></Form.Control>
                              <Button
                                className="support_upload_btn"
                                onClick={handleImageClick}
                                style={{ position: "relative", zIndex: 1 }}
                              >
                                <img
                                  src={require("../assets/image/file_upload.png")}
                                  className="img-fluid"
                                  alt="btn-classify"
                                />
                                <span>Upload an attachment</span>
                              </Button>
                            </div>
                            <Form.Text>
                              {values.attachment?.name ||
                                "(Note: .jpg/.png/.jpeg/.mp4 max 5MB)"}
                            </Form.Text>
                            <p className="text-danger">{errors?.attachment || ""}</p>
                          </Col>
                        </Row>
                      </Form>
                      <div className="mt-3 text-center">
                        <button
                          className="primary-btn w-100"
                          onClick={handleSubmit}
                          disabled={loading.create}
                        >
                          {loading.create ? (
                            <Spinner />
                          ) : (
                            <Fragment>
                              <span> Submit Ticket</span>
                              <img
                                src={require("../assets/image/btn-arrow.png")}
                                className="img-fluid ms-2"
                                alt="submit-ticket"
                              />
                            </Fragment>
                          )}
                        </button>
                      </div>
                    </>
                  )}
                  {visibleDiv === "TicketDetails" && (
                    <div className="ticket_panel">
                      {!isEmpty(tickets) &&
                        tickets.map((ticket, index) => {
                          return (
                            <div
                              className={`com-border mb-3 ${
                                index == activeTicket ? "active" : ""
                              }`}
                              onClick={() => setActiveTicket(index)}
                            >
                              <h4>Ticket {formatNumber(index)}</h4>
                              <div className="ticket_panel_details">
                                <label>
                                  Sub: <span>{ticket?.categoryId?.categoryName}</span>
                                </label>
                                <div className="ticket_panel_bottom">
                                  <label>
                                    [ Ticket ID: <span>{ticket.ticketId}</span>]
                                  </label>
                                  <label>
                                    [ Status: <span>{capitalize(ticket.status)}</span>]
                                  </label>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  )}
                </div>

                {/* <div className="m-auto">
                  <img
                    src={require("../assets/image/ticket.png")}
                    className="img-fluid"
                    alt="empty"
                  />
                  <p>No Records Found</p>
                </div> */}
              </Col>
              <Col xl={7} lg={6} className="mb-4">
                <div className="com-border suppot-height support_empty mt-4">
                  {visibleDiv === "TicketDetails" && tickets.length == 0 && (
                    <div className="m-auto">
                      <img
                        src={require("../assets/image/supportempty.png")}
                        className="img-fluid pe-2"
                        alt="empty"
                      />
                      <p className="text">Your Support Tickets List is Empty.</p>
                    </div>
                  )}
                  {visibleDiv === "TicketDetails" && tickets.length > 0 && (
                    <>
                      <div className="ticket_details">
                        <h4>Ticket Details</h4>
                        <div className="ticket_details_flex">
                          <label>
                            Sub: <span>{tickets[activeTicket]?.title}</span>
                          </label>
                          <div className="ticket_panel_bottom">
                            <label>
                              [ Ticket ID: <span>{tickets[activeTicket]?.ticketId}</span>]
                            </label>
                            <label>
                              [ Status:{" "}
                              <span>{capitalize(tickets[activeTicket]?.status)}</span>]
                            </label>
                          </div>
                        </div>
                        <div className="msg_box_wrapper">
                          {!isEmpty(tickets[activeTicket]) &&
                            tickets[activeTicket].reply.map((item, index) => {
                              return (
                                <Fragment>
                                  {item.messageBy === "user" ? (
                                    <div className="msg_box_panel">
                                      <div className="msg_box_div">
                                        <div className="msg_box_content">
                                          <p>{item.message}</p>
                                          {console.log(item?.attachment,"item?.attachment")
                                          }
                                          {item?.attachment && (
                                            <Link to={config.API_HOST + item.attachment} target="_blank" className="text-dark">
                                              <p>{item.attachment} View file</p>
                                            </Link>
                                          )}
                                        </div>
                                        <img
                                          src={`${config.API_HOST}/${account.profilePic}`}
                                          className="img-fluid support_profile"
                                          alt="Icon"
                                          style={{
                                            borderRadius: "50%",
                                            backgroundColor: "#fff",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </div>
                                      <h6>
                                        {moment(item.createdAt).format(
                                          "DD-MM-YYYY hh:mm A",
                                        )}
                                      </h6>
                                    </div>
                                  ) : (
                                    <div className="msg_box_panel admin_msg_box_panel">
                                      <div className="msg_box_div msg_box_div1">
                                        <img
                                          src={require("../assets/image/userImage.png")}
                                          className="img-fluid support_profile"
                                          alt="Icon"
                                          style={{
                                            borderRadius: "50%",
                                            backgroundColor: "#fff",
                                            objectFit: "cover",
                                          }}
                                        />
                                        <div className="msg_box_content">
                                          <p>{item.message}</p>
                                        </div>
                                      </div>
                                      <h6>
                                        {moment(item.createdAt).format(
                                          "DD-MM-YYYY hh:mm A",
                                        )}
                                      </h6>
                                    </div>
                                  )}
                                </Fragment>
                              )
                            })}
                        </div>
                        {tickets[activeTicket]?.status !== "closed" && (
                          <div className="support_txtbox">
                            <Form.Control
                              placeholder="Write a message"
                              type="text"
                              name="message"
                              value={replyValues.message}
                              onChange={replyHandleChange}
                            ></Form.Control>
                            <div className="support_txtbox_btngrp">
                              <div className="support_upload_btn support_upload_btn1">
                                <Form.Control
                                  type="file"
                                  ref={replyFileInputRef}
                                  className="file_input"
                                  hidden
                                  accept=".jpg,.jpeg,.png,.mp4"
                                  onChange={handleReplyFileChange}
                                ></Form.Control>
                                <div
                                  className="me-2"
                                  onClick={handleImageClick}
                                  style={{
                                    position: "relative",
                                    zIndex: 1,
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={require("../assets/image/file_upload.png")}
                                    className="img-fluid"
                                    alt="btn-classify"
                                  />
                                </div>
                                {/* {console.log(replyValues.attachment?.name,"fille")} */}
                                <Form.Text className="text-dark">
                                  {replyValues.attachment?.name || ""}
                                </Form.Text>
                              </div>

                              <button
                                className="primary-btn"
                                disabled={loading.reply}
                                onClick={replyHandleSubmit}
                              >
                                {loading.reply ? (
                                  <Spinner />
                                ) : (
                                  <Fragment>
                                    <span>Send</span>
                                    <img
                                      src={require("../assets/image/btn-arrow.png")}
                                      className="img-fluid ms-2"
                                      alt="respond-ticket"
                                    />
                                  </Fragment>
                                )}
                              </button>
                            </div>
                          </div>
                        )}{" "}
                      </div>
                      {tickets[activeTicket]?.status !== "closed" && (
                        <div className="close_support">
                          <button className="primary-btn" onClick={() => closeTicket()}>
                            Close
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <Footer />
        </main>
      </div>
    </>
  )
}
