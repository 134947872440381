import React, { useEffect, useMemo, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { calculateMarkup, getPriceCnvData, getPriceData } from "../../api/currency"
import ReactApexChart from "react-apexcharts"
import { useDispatch, useSelector } from "react-redux"
import { toFixedDown } from "../../lib/roundOf"
import { setPriceConversion } from "../../redux/slices/priceConversion"

const options = color => {
  return {
    chart: {
      type: "area",
      height: 90,
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
      colors: [color],
    },
    fill: {
      // opacity: 0.3,
      type: "gradient",

      gradient: {
        shade: "light",
        shadeIntensity: 0.5,
        opacityFrom: 1,
        opacityTo: 0,
        colorStops: [
          {
            offset: 0,
            color: color,
            opacity: 0.3,
          },
          {
            offset: 100,
            color: color,
            opacity: 0,
          },
        ],
      },
    },
    tooltip: {
      enabled: true,
    },
    // color,
    colors: [color],
  }
}

const series = (data,markupPercentage,type) => {
  const markupPrice = data.map((item) => toFixedDown(calculateMarkup(item,markupPercentage,type)))
  return [
    {
      name: "Market Price",
      data:markupPrice
    },
  ]
}
export default function CryptoCoins({type}) {

  const currencyData = useSelector((state) => state.currency)

  // const dispatch = useDispatch()

  // const getPriceCnvDataApiCall = async () => {
  //   try { 
  //     const { success, result } = await getPriceData()
  //     if (success) {
  //       dispatch(setPriceConversion(result.data))
  //     }
  //   } catch (error) {
  //     console.log("error", error)
  //   }
  // }

  const priceData = useSelector((state) => state.priceConversion)

  const currentmarkupPercentage = (item) => {   
    if (currencyData.length > 0) {
      const data = currencyData.find((data) => data.coin === item);
      console.log(data,"percent");
      return data ? data.markupPercentage : 0
    }
  }

  // useEffect(() => {
  //   getPriceCnvDataApiCall()
  // }, [])

  return (
    <>
      <Row className="crypto_card_row">
        {Array.isArray(priceData) &&
          priceData.length > 0 &&
          priceData.map(item => {
            const classNames = {
              ETH: "blue_card",
              BTC: "orange_card",
              default: "green_card",
            }
            const cardName = classNames[item.baseSymbol] || classNames.default
            const textColor = item.difference_cad < 0 ? "text_red" : "text_green"
            const imageMap = {
              ETH: "eth1",
              BTC: "bitcoin",
              default: "usdcimg",
            }

            const imageName = imageMap[item.baseSymbol] || imageMap.default

            const markupPercentage = currentmarkupPercentage(item.baseSymbol);
            
            return (
              <Col lg={4} md={6} key={item.baseSymbol}>
                <div className={`crypto_card ${cardName}`}>
                  <div className="crypto_card_top">
                    <div className="crypto_card_top_left">
                      <img
                        src={require(`../../assets/image/${imageName}.png`)}
                        className="img-fluid"
                        alt={`${item.baseSymbol} logo`}
                      />
                      <span>{item.baseSymbol}</span>
                    </div>
                    <div className="crypto_card_top_right">
                      <div className={`crypto_card_badge `}>
                        <img
                          src={require(`../../assets/image/${item.difference_cad < 0 ? "downtrend_icon" : "uptrend_icon"
                            }.png`)}
                          className="img-fluid"
                          alt="Trend icon"
                        />
                        <h5 className={textColor}>
                          {item.difference_cad < 0
                            ? `${item.difference_cad?.toFixed(2)}`
                            : `+${item.difference_cad?.toFixed(2)}`}{" "}
                          <span className="d-block">
                            ({item.percent_cad?.toFixed(2)} %)
                          </span>
                        </h5>
                      </div>
                      <h6>24 hours</h6>
                    </div>
                  </div>
                  <div className="crypto_card_bottom">
                    <div className="crypto_card_price">
                      {console.log(item.convertPrice_cad,"convertPriceInCoin",type)}
                      <h3>
                        {toFixedDown(calculateMarkup(item.convertPrice_cad, markupPercentage,type))} <span>CAD</span>
                      </h3>
                      <h3>
                        {toFixedDown(item.convertPrice_usd)} <span>USD</span>
                      </h3>
                    </div>
                    <div className="dashboard_chart">
                      <ReactApexChart
                        options={options(item.difference_cad < 0 ? "red" : "green")}
                        series={series(item.candleData,markupPercentage,type)}
                        type="area"
                        height={60}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            )
          })}
      </Row>
    </>
  )
}
