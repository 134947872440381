import { modifySignal } from "../lib/signal"
import { clearNotification, setUnreadNotification } from "../redux/slices/notification"
import axios, { handleResponse } from "./Axios"

export const getFeeData = async params => {
  try {
    const response = await axios({
      url: "/wallet/feeData",
      method: "GET",
      params,
    })
    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const getNotificationHistory = async params => {
  try {
    const response = await axios({
      url: "/user/notification",
      method: "GET",
      params,
    })
    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const getUnreadNotification = async (dispatch, isRecentActivity = false) => {
  try {
    const response = await axios({
      url: "/user/get-all-notification",
      method: "GET",
      params: { isRecentActivity },
    })
    console.log(response.data, "check")
    isRecentActivity &&
      dispatch(
        setUnreadNotification({
          count: response.data.count,
          NoticeData: response.data.result,
        }),
      )
    !isRecentActivity && modifySignal(response.data.count)
    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const getUnreadNotificationHelper = async (dispatch, isRecentActivity = false) => {
  try {
    const response = await axios({
      url: "/user/get-all-notification",
      method: "GET",
      params: { isRecentActivity },
    })
    dispatch(
      setUnreadNotification({
        count: response.data.count,
        NoticeData: response.data.result,
      }),
    )
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const getReadNotification = async (dispatch, isRecentActivity = false) => {
  try {
    const response = await axios({
      url: "/user/notification",
      method: "GET",
      params: { isRecentActivity },
    })
    isRecentActivity &&
      dispatch(
        setUnreadNotification({
          count: response.data.count,
          NoticeData: response.data.result,
        }),
      )
    !isRecentActivity && modifySignal(response.data.count)
    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const deleteNotification = async data => {
  try {
    const response = await axios({
      url: `/user/notification?id=${data}`,
      method: "DELETE",
    })

    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}

export const clearNotifications = async dispatch => {
  try {
    const response = await axios({
      url: `/user/notification`,
      method: "PATCH",
    })
    dispatch(clearNotification())
    modifySignal(0)
    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
export const FetchunReadNotice = async (dispatch, data) => {
  try {
    dispatch(setUnreadNotification(data))
    modifySignal(data.count)
  } catch (err) {
    console.log("FetchunReadNotice", err)
  }
}

export const markAllReadNotification = async () => {
  try {
    const response = await axios({
      url: `/user/notification`,
      method: "PUT",
    })
    return handleResponse(response, "success")
  } catch (error) {
    console.log(error)
    return handleResponse(error, "error")
  }
}
