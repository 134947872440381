import * as yup from "yup"

import React, { useEffect, useState } from "react"
import { Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Button } from "@mui/material"
import { useFormik } from "formik"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import isEmpty from "is-empty"
import logo from "../../../assets/image/logo.svg"
import { register } from "../../../api/auth"
import { toast } from "../../../lib/toast"
import { PASSWORD } from "../../../constant/regex"

const RegisterForm = () => {
  const initialValues = {
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    isTerms: false,
  }
  const validationSchema = yup.object({
    userName: yup.string().required("Username is required"),
    email: yup.string().email("Invalid email").matches(/^[^\s/*]+@[^\s/*]+\.[^\s/*]+$/, "Email cannot contain special characters").required("Email is required"),
    password: yup
      .string()
      .min(8, "Password should be minimum of 8 characters")
      .max(32, "Password can have maximum of 32 characters")
      .matches(PASSWORD, {
        message: "Password should contain 1 uppercase, 1 lowercase, 1 number & 1 symbol",
      })
      // .oneOf([yup.ref('confirmPassword'), null], 'Passwords must match')
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords mismatch")
      .required("Confirm password is required"),
    isTerms: yup.boolean().isTrue("Accept to Terms & Policies before signing up"),
  })

  const { values, errors, setErrors, touched, setValues, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: registerSubmission,
    })

  const { executeRecaptcha } = useGoogleReCaptcha()

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    confirmPassword: false,
  })

  const handleReCaptcha = async () => {
    try {
      if (!executeRecaptcha) {
        toast({ message: "Recaptcha required", type: "error" }, dispatch)
        return ""
      }
      return await executeRecaptcha("login")
    } catch (err) {
      toast({ message: "Invalid recaptcha", type: "error" }, dispatch)
      return ""
    }
  }

  const navigate = useNavigate()
  async function registerSubmission(values, { setErrors, resetForm }) {
    try {
      // const captcha = await handleReCaptcha()
      // if (isEmpty(captcha)) {
      //   setLoading(false)
      //   toast({ message: "Invalid recaptcha", type: "error" }, dispatch)
      //   return
      // }
      const data = {
        ...values,
        // reCaptcha: captcha,
      }
      setLoading(true)
      const { success, message, errors } = await register(data)
      if (success) {
        setLoading(false)
        toast({ message, type: "success" }, dispatch)
        resetForm()
        return
      } else if (!success && !isEmpty(errors)) {
        setLoading(false)
        setErrors(errors)
        return
      } else {
        setLoading(false)
        toast({ message, type: "error" }, dispatch)
        navigate("/login")
      }
    } catch (error) {
      setLoading(false)
      console.log("registerSubmission_error", error)
      toast({ message: "Something went wrong", type: "error" }, dispatch)
    }
  }

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSubmit()
    }
  }

  return (
    <div className="auth_wrapper">
      <Row>
        <Col xl={6} lg={6} md={6} className="">
          <img
            src={require("../../../assets/image/logpage.png")}
            alt="Login"
            className="img-fluid logpage"
          />
        </Col>
        <Col xl={6} lg={6} md={6} className="">
          <img src={logo} alt="Logo" className="logo_img" />
          <div className="auth_bg ">
            <div className="auth_header auth_header_flex">
              <h2>Sign Up</h2>
              <img
                src={require("../../../assets/image/desktop_login_icon (2).png")}
                alt="Login"
              />
            </div>

            <Form className="auth_form" onKeyPress={handleKeyPress}>
              <Form.Control
                type="text"
                placeholder="Username"
                className="mb-3"
                value={values.userName}
                onChange={handleChange}
                id="userName"
                isInvalid={touched.userName && !!errors.userName}
                name="userName"
              />
              <p className="text-danger">
                {console.log(errors.userName)}
                {(touched.userName && errors.userName) || ""}
              </p>

              <Form.Control
                type="email"
                placeholder="Email"
                className="mb-3"
                value={values.email}
                onChange={handleChange}
                id="email"
                isInvalid={touched.email && !!errors.email}
                name="email"
              />
              <p className="text-danger">{(touched.email && errors.email) || ""}</p>

              <div className="position_eye">
                <Form.Control
                  type={passwordVisibility.password ? "text" : "password"}
                  placeholder="Password"
                  className="mb-3"
                  value={values.password}
                  onChange={handleChange}
                  id="password"
                  isInvalid={touched.password && !!errors.password}
                  name="password"
                />
                <div
                  className="showpass_icon"
                  onClick={() =>
                    setPasswordVisibility(prev => ({
                      ...prev,
                      password: !prev.password,
                    }))
                  }
                >
                  {passwordVisibility.password ? (
                    <i className="fa-regular fa-eye"></i>
                  ) : (
                    <i className="fa-regular fa-eye-slash"></i>
                  )}
                </div>
                <p className="text-danger">
                  {(touched.password && errors.password) || ""}
                </p>
              </div>
              <div className="position_eye">
                <Form.Control
                  type={passwordVisibility.confirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  className="mb-3"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  id="confirmPassword"
                  name="confirmPassword"
                  isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                />
                <div
                  className="showpass_icon"
                  onClick={() =>
                    setPasswordVisibility(prev => ({
                      ...prev,
                      confirmPassword: !prev.confirmPassword,
                    }))
                  }
                >
                  {passwordVisibility.confirmPassword ? (
                    <i className="fa-regular fa-eye"></i>
                  ) : (
                    <i className="fa-regular fa-eye-slash"></i>
                  )}
                </div>
                <p className="text-danger">
                  {(touched.confirmPassword && errors.confirmPassword) || ""}
                </p>
              </div>
              <Form.Check>
                <Form.Check.Input
                  type="checkbox"
                  checked={values.isTerms}
                  id="isTerms"
                  name="isTerms"
                  onChange={handleChange}
                  isInvalid={touched.isTerms && !!errors.isTerms}
                />
                <Form.Check.Label htmlFor="isTerms">
                  I have read and agree to the <Link to="/terms">Terms of use</Link>
                </Form.Check.Label>
              </Form.Check>
              <Form.Control.Feedback type="invalid">
                {" "}
                {/* Bootstrap feedback for validation */}
                {errors.isTerms}
              </Form.Control.Feedback>
              <div className="mt-3">
                <button
                  className="primary-btn w-100"
                  type="button"
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <Spinner />
                  ) : (
                    <>
                      {" "}
                      Sign Up
                      <img
                        src={require("../../../assets/image/btn-arrow.png")}
                        className="img-fluid"
                      />
                    </>
                  )}
                </button>
              </div>
              <p className="signup_link">
                Already have an account? <Link to="/login">Log in</Link>
              </p>
            </Form>
            <div className="copyright">
              <p>Copyright © 2024 Neco Smart. All rights reserved.</p>
              <p>Privacy Policy | Terms of Service</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default RegisterForm
