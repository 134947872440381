import { useFormik } from "formik"
import { default as React, useContext, useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { calculateMarkup, getPriceCnvData } from "../../api/currency"
import ConfirmModal from "./confirmModal"

import btcImage from "../../assets/image/btc1.png"
import ethImage from "../../assets/image/eth1.png"
import usdcImage from "../../assets/image/usdc1.png"
import { useSelector } from "react-redux"
import account from "../../redux/slices/account"
import { toast } from "../../lib/toast"
import { getActiveCurrencyList, getCurrencyList } from "../../api/user"
import isEmpty from "is-empty"
import { SocketContext } from "../../context/SocketContext"
import { convert, toFixedDown } from "../../lib/roundOf"
import { truncateDecimals } from "../../lib/calculation"

// const tokens = [
//   {
//     name: "BTC",
//     imgSrc: btcImage,
//     fullName: "Bitcoin",
//   },
//   {
//     name: "ETH",
//     imgSrc: ethImage,
//     fullName: "Ethereum",
//   },
//   {
//     name: "USDC",
//     imgSrc: usdcImage,
//     fullName: "USD Coin",
//   },
// ]
const BuyCrypto = ({ show, handleShow, handleShow1, handleClose, handleClose1 }) => {
  const dispatch = useDispatch()
  const [isActive, setActive] = useState(false)
  const [isBuyActive, setBuyActive] = useState(false)
  // const [priceCnvData, setPriceCnvData] = useState([])
  const [currentData, setCurrentData] = useState({})
  const [cryptoBuyModal, setCryptoBuyModal] = useState(false)

  const [selectedToken, setSelectedToken] = useState()

  const buyModalHide = () => setCryptoBuyModal(false)

  const initialFormValue = { amount: "", currency: "BTC", quantity: "", address: "" }

  const {
    values,
    errors,
    touched,
    setErrors,
    setValues,
    handleChange: formikHandleChange,
    handleSubmit,
  } = useFormik({ initialValues: initialFormValue, onSubmit: dataUpdate })

  const selectToken = token => {
    setSelectedToken({
      name: token.coin,
      imgSrc: token.image,
      fullName: token.name,
    })
    setValues(prev => ({
      ...prev,
      currency: token.coin,
    }))
    setActive(false)
  }

  const percentConvertPrice = (price, percentage) => {
    if (price && percentage) {
      return price * (percentage / 100)
    }
    return 0
  }

  const [fee, setFee] = useState()

  const currencyData = useSelector(state => state.currency).find(
    data => data.coin === (values.currency || "BTC"),
  )

  const priceCnvData = useSelector(state => state.priceConversion)

  const priceConversion = async (value = "BTC") => {
    const withdrawFee = currencyData ? currencyData.withdrawFee : ""
    const currentCurrencyData = priceCnvData.find(item => item.baseSymbol === value)
    console.log(currentCurrencyData, "currencyData")
    if (currentCurrencyData && values?.amount) {
      const cadPrice = currentCurrencyData.convertPrice_cad
      const decimals = value == "BTC" ? 8 : value == "ETH" ? 8 : 2
      console.log(values.amount, currencyData.markupPercentage, "check")

      const convertPriceWithMarkupPercentage = toFixedDown(
        calculateMarkup(cadPrice, currencyData.markupPercentage),
      )

      const convertQuantity = convert(values.amount / convertPriceWithMarkupPercentage)

      const quantity = truncateDecimals(parseFloat(convertQuantity), decimals)

      if (withdrawFee) {
        const feePrice = percentConvertPrice(values.amount, withdrawFee)
        const convertQuantity = convert(
          (values.amount - feePrice) / convertPriceWithMarkupPercentage,
        )
        const convertTruncate = truncateDecimals(parseFloat(convertQuantity), decimals)
        setFee(convertTruncate)
      } else {
        setFee(quantity)
      }
      setValues(prev => ({
        ...prev,
        quantity,
        // quantity: values.amount / cadPrice,
      }))
    } else {
      setValues(prev => ({
        ...prev,
        quantity: 0,
      }))
    }
    await dataUpdate()
  }

  const handleManualChange = event => {
    console.log(values, "valiues")
    if (
      (event.target.value.includes(".") &&
        event.target.value.split(".")[1].length <= 2) ||
      !event.target.value.includes(".")
    ) {
      setValues(prev => ({
        ...prev,
        amount: event.target.value,
      }))
    } else {
      setErrors({ ...errors, amount: "Decimal must be less than or equal to 2" })
    }
  }

  async function dataUpdate() {
    if (priceCnvData.length > 0 && values) {
      console.log(priceCnvData, "isit")
      console.log(
        priceCnvData?.find(item => item.baseSymbol === values.currency),
        "isit",
      )

      // const data = {
      //   amount: values.amount,
      //   quantity: values.quantity,
      //   currentMarketPrice: priceCnvData?.find(item => item.baseSymbol === values.currency)?.convertPrice_cad,
      //   currency: values.currency,
      //   address: values.address,
      //   markupPercentage:currencyData.markupPercentage,
      //   withdrawFee:withdrawFee || ""
      // }
      // setCurrentData(data)
      setCryptoBuyModal(true)
    }
  }

  // const fetchData = async () => {
  //   const { success, result } = await getPriceCnvData()
  //   console.log("result", result)
  //   if (success) {
  //     setPriceCnvData(result.data)
  //   }
  // }

  const [kycStatus, setKycStatus] = useState({ idProof: "", addressProof: "" })

  // console.log(kycStatus, "data");

  const kycData = useSelector(state => state.account.kycStatus)

  const [tokens, setTokens] = useState()

  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (tokens && tokens.length > 0 && !currentCurrencyData) {
      setSelectedToken({
        name: tokens[index]?.coin || "",
        imgSrc: tokens[index]?.image || "",
        fullName: tokens[index]?.name || "",
      })
    }
  }, [tokens, index])

  useEffect(() => {
    priceConversion(values.currency)
    if (kycData) {
      setKycStatus(kycData)
    }
  }, [values.amount, values.currency, kycData])

  useEffect(() => {
    const getCurrencyData = async () => {
      const data = await getActiveCurrencyList()
      console.log(data.result, "data")
      setTokens(data.result)
    }
    getCurrencyData()
  }, [])

  console.log(tokens, "tokens")

  const toggleClass = () => {
    setActive(!isActive)
  }

  const isCad = useSelector(state => state.currency[0])

  console.log(tokens, "tokens")

  // const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    const inputElement = document.getElementById("amount")

    const preventScrollChange = event => {
      event.preventDefault() // Prevent scroll wheel from changing the input value
    }

    if (inputElement) {
      inputElement.addEventListener("wheel", preventScrollChange)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", preventScrollChange)
      }
    }
  }, [])

  const location = useLocation()

  const [coin, setCoin] = useState()

  useEffect(() => {
    if (location && location.state) {
      const { coin } = location.state
      // console.log(coin,"coin");
      setCoin(coin)
    }
  }, [location])

  const currentCurrencyData = useSelector(state => state.currency).find(
    data => data.coin === coin,
  )

  useEffect(() => {
    if (currentCurrencyData) {
      console.log(currentCurrencyData, "currentCurrencyData")
      setSelectedToken({
        name: currentCurrencyData.coin || "",
        imgSrc: currentCurrencyData.image || "",
        fullName: currentCurrencyData.name || "",
      })
      setValues(prev => ({
        ...prev,
        currency: currentCurrencyData.coin,
      }))
      setActive(false)
    }
  }, [currentCurrencyData])

  // const { socket } = useContext(SocketContext)

  // useEffect(() => {
  //   if (!socket) return
  //   socket.on("marketPrice", async data => {
  //     // console.log("marketPrice",result);
  //     await fetchData()
  //   })
  //   return () => {
  //     socket.off("marketPrice")
  //   }
  // }, [])

  return (
    <div>
      <h2 className="dash_title">Buy Crypto</h2>
      <div className="Buy_crypto_sec">
        <Row className="mb-3">
          <Col lg="6" className="border_box_head">
            <div className="border_box">
              <Row>
                <Col lg="7" md="7" sm="7">
                  <p>You Send</p>
                  <div className="send_details">
                    <div className="img_border">
                      <img src={require("../../assets/image/flag.png")} alt="Icon" />
                    </div>
                    <h6>Dollar </h6>
                  </div>
                </Col>
                <Col lg="5" md="5" sm="5">
                  <div className="input_end">
                    <Form.Group controlId="formGridAmount">
                      <Form.Control
                        type="number"
                        placeholder="Amount"
                        className="form-control"
                        onChange={handleManualChange}
                        name="amount"
                        id="amount"
                        value={values.amount}
                      />
                      <p className="red_text">
                        {(touched.amount && errors.amount) || ""}
                      </p>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg="6">
            <div className="border_box">
              <Row>
                <Col lg="7" md="7" sm="7">
                  <p className="text-nowrap">
                    {" "}
                    You will get (after fee deduction : {fee && fee})
                  </p>
                  <div className="swap_container">
                    {/* <div className="swap_select_token_wrap">
                      <div className="swap_select_token_inner">
                        <div
                          className={`swap_select_token ${
                            isActive ? "swap_select_token_open" : ""
                          }`}
                          onClick={toggleClass}
                        >
                          <div className="swap_select_token_option">
                            <div className="img_border">
                              <img
                                src={require("../../assets/image/btc1.png")}
                                alt="Crypto Coin"
                              />
                            </div>
                            <span className="swap_token_title">USDC</span>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          isActive
                            ? "token_search_container token_search_container_open"
                            : "token_search_container"
                        }
                      >
                        <div className="token_search_list">
                          <div className="token_search_list_row">
                            <span className="token_search_list_row_img">
                              <img
                                src={require("../../assets/image/btc1.png")}
                                alt="Crypto Coin"
                              />
                            </span>
                            <span className="token_search_list_cur_name">BTC</span>
                            <span className="token_search_list_coin_name">Bitcoin</span>
                          </div>
                          <div className="token_search_list_row">
                            <span className="token_search_list_row_img">
                              <img
                                src={require("../../assets/image/eth1.png")}
                                alt="Crypto Coin"
                              />
                            </span>
                            <span className="token_search_list_cur_name">ETH</span>
                            <span className="token_search_list_coin_name">Ethereum</span>
                          </div>
                          <div className="token_search_list_row">
                            <span className="token_search_list_row_img">
                              <img
                                src={require("../../assets/image/usdc1.png")}
                                alt="Crypto Coin"
                              />
                            </span>
                            <span className="token_search_list_cur_name">USDC</span>
                            <span className="token_search_list_coin_name">USD Coin</span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="swap_select_token_wrap">
                      {selectedToken && (
                        <div className="swap_select_token_inner">
                          <div
                            className={`swap_select_token ${
                              isActive ? "swap_select_token_open" : ""
                            }`}
                            onClick={toggleClass}
                          >
                            <div className="swap_select_token_option">
                              <div className="img_border">
                                <img src={selectedToken.imgSrc} alt="Crypto Coin" />
                              </div>
                              <span className="swap_token_title">
                                {selectedToken.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                      <div
                        className={
                          isActive
                            ? "token_search_container token_search_container_open"
                            : "token_search_container"
                        }
                      >
                        <div className="token_search_list">
                          {Array.isArray(tokens) &&
                            tokens.map((token, index) => {
                              console.log(token.coin !== "CAD", "djdnkdn")
                              console.log(token.image)

                              return token.coin !== "CAD" ? (
                                <div
                                  key={index}
                                  className="token_search_list_row"
                                  onClick={() => {
                                    setIndex(index)
                                    selectToken(token)
                                  }}
                                >
                                  <span className="token_search_list_row_img">
                                    <img src={token.image} alt="Crypto Coin" />
                                  </span>
                                  <span className="token_search_list_cur_name">
                                    {token.coin}
                                  </span>
                                  <span className="token_search_list_coin_name">
                                    {token.name}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="5" md="5" sm="5">
                  <div className="input_end">
                    <Form.Control
                      type="number"
                      id="quantity"
                      placeholder="Quantity"
                      className="form-control"
                      name="quantity"
                      value={values.quantity}
                      disabled
                    />
                    <p className="red_text">
                      {(touched.quantity && errors.quantity) || ""}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg="6">
            <label htmlFor="quantity" className="form-label lable-cls">
              Deposit Address
            </label>
            <div className="input-group mb-3 border-none">
              <span className="input-group-text" id="basic-addon3">
                <img src={require("../../assets/image/icon5.png")} alt="Icon" />
              </span>

              <Form.Control
                type="text"
                placeholder="Wallet Address"
                className="form-control"
                id="address"
                name="address"
                value={values.address}
                onChange={formikHandleChange}
              />
            </div>
            <p className="red_text">{(touched.address && errors.address) || ""}</p>
          </Col>
        </Row>
        {priceCnvData && isCad && isCad.status === "active" && (
          <div className="btn-center">
            <div className="btn-center">
              <div
                className=""
                // onClick={() => {
                //   if (kycStatus.idProof !== "approved") {
                //     // setIsDisabled(true)
                //     toast({ message: "Kyc is not verified", type: "error" }, dispatch)
                //   }
                // }}
              >
                <Link
                  className="primary-btn"
                  onClick={e => {
                    e.preventDefault()
                    // if (kycStatus.idProof === "approved") {
                    console.log(values.amount, "check")
                    setCurrentData({
                      amount: values.amount,
                      currentMarketPrice: priceCnvData?.find(
                        item => item.baseSymbol == values.currency,
                      )?.convertPrice_cad,
                      quantity: values.quantity,
                      address: values.address,
                      currency: values.currency,
                    })
                    handleShow()
                    // }
                  }}
                >
                  Checkout
                  <img
                    src={require("../../assets/image/btn-arrow.png")}
                    className="img-fluid"
                  />
                </Link>
              </div>
            </div>
            {/* <Button className="primary-btn" onClick={handleShow}>
            Checkout
            <img
              src={require("../../assets/image/btn-arrow.png")}
              className="img-fluid"
            />
          </Button> */}
          </div>
        )}
      </div>
      <h2 className="dash_title mt-4">Order Limits and fees</h2>
      <div className="Order_limit">
        <p>
          For lightning fast transaction and confirmation times, we always send your
          Bitcoin ti its network with an attractive miner's fee. Fees may increase or
          decrease depending on congestion of the underlying bitcoin blockchain network
          and associated miner's fees to broadcast transaction.
        </p>
        <p>
          If your purchase is greater tha $350.00. the minimum order fee is waived you
          will still benefit from fast confirmation times
        </p>
      </div>
      <ConfirmModal
        handleClose={handleClose}
        show={show}
        handleClose1={handleClose1}
        handleShow1={handleShow1}
        currentData={{
          amount: values.amount,
          // quantity: values.quantity,
          // currentMarketPrice: priceCnvData?.find(item => item.baseSymbol === values.currency)?.convertPrice_cad,
          currency: values.currency,
          address: values.address,
          markupPercentage: currencyData?.markupPercentage || null,
          withdrawFee: currencyData ? currencyData.withdrawFee : 0,
        }}
        coin={values.currency}
        priceCnvData={
          priceCnvData.find(item => item.baseSymbol == values.currency) || null
        }
        percentConvertPrice={percentConvertPrice}
      />
    </div>
  )
}

export default BuyCrypto
