import axios, { removeAuthorization } from "../config/axios"
import isLogin from "../lib/isLogin"
import { removeAuthToken } from "../utils/cookie"

// axios.defaults.baseURL = config.API_URL
// axios.defaults.paramsSerializer = paramsEncoder
// axios.defaults.headers.common["TIMEZONE"] =
//   Intl.DateTimeFormat().resolvedOptions().timeZone

export const handleResponse = (responseData, type = "success", doc = false) => {
  try {
    if (doc && type === "success" && responseData && responseData.data) {
      return { data: responseData.data }
    }
    if (type === "success" && responseData && responseData.data) {
      console.log(responseData.data,"check");
      return responseData.data
    } else if (
      type == "error" &&
      responseData &&
      responseData.response &&
      responseData.response.status == 401
    ) {
      if (isLogin()) {
        removeAuthToken()
        removeAuthorization()
        window.location.href = "/login"
        document.cookie =
          "user_token" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
        return true
      }
    } else if (
      type === "error" &&
      responseData &&
      responseData.response &&
      responseData.response.data
    ) {
      return responseData.response.data
    } else {
      return {
        success: false,
        message: "Something went wrong",
      }
    }
  } catch (error) {
    return {
      success: false,
      message: "Something went wrong",
    }
  }
}

export default axios
