import React, { useCallback, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { getVerified } from "../api/auth"
import { Spinner } from "react-bootstrap"

const Verify = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [token] = useState(params?.token || null)
  const [loading, setLoading] = useState(false)
  const [showLogin, setLogin] = useState(false)
  const [timer, setTimer] = useState(10)
  const [message, setMessage] = useState("We're working on it")

  const handleVerify = useCallback(async () => {
    try {
      if (!token) return
      setLoading(true)
      setMessage("Please hold on, currently we are verifying you...")

      let { success, message } = await getVerified({ userId: token })
      setLoading(false)
      setMessage(message)
      if (success) {
        setLogin(true)
      }
    } catch (error) {
      setMessage("Cannot verify at this moment")
    }
  }, [token])

  useEffect(() => {
    handleVerify()
  }, [handleVerify])

  useEffect(() => {
    let intervalId = ""
    if (showLogin) {
      intervalId = setInterval(() => {
        setTimer(prev => {
          if (prev === 0) {
            navigate("/login")
          }
          return prev - 1
        })
      }, 1000)
    }

    return () => {
      setTimer(10)
      clearInterval(intervalId)
    }
  }, [showLogin, navigate])

    return (
      <div className="verify-container">
        <div>
          {loading && <Spinner />}
          <p>{message}</p>
          {showLogin && (
            <>
              <Link to={"/login"}>Click Here To Login</Link>
              <span>Auto-redirecting in {timer} seconds</span>
            </>
          )}
        </div>
      </div>
    )
}

export default Verify
