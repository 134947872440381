import React, { useState } from "react"
import { Form, Spinner } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import InputGroup from "react-bootstrap/InputGroup"
import Modal from "react-bootstrap/Modal"
import { useDispatch } from "react-redux"
import { manualTriggerApi } from "../../../api/wallet"
import { toast } from "../../../lib/toast"
const initialValues = {
  seqQuiz: "",
  seqAns: "",
  amount: "",
}

const ManualTrigger = ({ show, handleClose,fetchBalance }) => {
  const [formValue, setFormValue] = useState(initialValues)
  const [errors, setErrors] = useState({})

  const [isEditable, setIsEditable] = useState(false)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const handleFormChange = e => {
    setFormValue(prevData => {
      return { ...prevData, [e.target.name]: e.target.value }
    })
  }

  const manualTrigger = async () => {
    try {
      setLoading(true)
      const { success, message, errors } = await manualTriggerApi({
        seqQuiz: formValue.seqQuiz,
        seqAns: formValue.seqAns,
        amount: formValue.amount,
      })
      if (success) {
        setLoading(false)
        setErrors({})
        toast({ message, type: "success" }, dispatch)
        setIsEditable(false)
        handleClose()
      } 
      else if(!success){
        setErrors({})
        toast({ message, type: "error" }, dispatch)
        setLoading(false)
      }
      else if (!success && errors) {
        setErrors(errors)
        setLoading(false)
      } else {
        toast({ message: "Something went wrong", type: "error" }, dispatch)
        setIsEditable(false)
        setLoading(false)
      }
    } catch (error) {
      setIsEditable(false)
      setLoading(false)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    manualTrigger()
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="md"
      centered
      className="order_confrim_model"
    >
      <Modal.Header closeButton>
        <Modal.Title>Manual Trigger</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="seqQuiz">
            <Form.Label>Secret Question & Secret Answer</Form.Label>
            <div className="d-flex  align-items-center">
              <InputGroup className="mb-3 input_style input_group  me-3">
                <Form.Control
                  type="text"
                  name="seqQuiz"
                  value={formValue.seqQuiz}
                  placeholder="Secret Question"
                  className="primary_input  border_input "
                  onChange={handleFormChange}
                  style={{ paddingLeft: "20px" }}
                />
              </InputGroup>
              <InputGroup className="mb-3 input_style input_group  me-3">
                <Form.Control
                  type="text"
                  name="seqAns"
                  value={formValue.seqAns}
                  placeholder="Secret Answer"
                  className="primary_input  border_input "
                  onChange={handleFormChange}
                  style={{ paddingLeft: "20px" }}
                />
              </InputGroup>
              <InputGroup className="mb-3 input_style input_group  me-3">
                <InputGroup.Text id="basic-addon1" className="input-symbol border_input">
                  $
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="amount"
                  value={formValue.amount}
                  placeholder="Amount"
                  className="primary_input  border_input "
                  onChange={handleFormChange}
                  style={{ paddingLeft: "20px" }}
                />
              </InputGroup>

              <p className="text-danger">{errors?.amount}</p>
            </div>
          </Form.Group>

          <div className="model_btn">
            <Button
              type="submit"
              className="flex-fill"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {" "}
                  Submit
                  <img
                    src={require("../../../assets/image/arrow_orange.png")}
                    className="img-fluid orange_arrow"
                  />
                  <img
                    src={require("../../../assets/image/btn-arrow.png")}
                    className="img-fluid white_arrow"
                  />
                </>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default ManualTrigger
